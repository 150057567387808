import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { NetworkStatus } from "@apollo/client/core";
import { Apollo } from "apollo-angular";
import type { DocumentNode } from "graphql";
import { parse } from "@0no-co/graphql.web";
import { bufferWhen, EMPTY, Observable, Subject } from "rxjs";
import { catchError, debounceTime, filter, map, switchMap, tap } from "rxjs/operators";
import { isPlatformServer } from "@angular/common";
import { WatchQueryFetchPolicy } from "@apollo/client/core/watchQueryOptions";
import { TypedDocumentNode } from "@apollo/client";
import { DocumentTypeDecoration } from "@graphql-typed-document-node/core";
import { captureException } from "@sentry/angular-ivy";

import { SignOutDocument } from "../../../common/gql/graphql";
import { NotificationService } from "../notification/notification.service";
import { StateService } from "../state.service";
import { ModalService } from "../modal/modal.service";

interface TypedDocumentString<TResult, TVariables> extends String {
    toString(): string & DocumentTypeDecoration<TResult, TVariables>;
}

@Injectable({
    providedIn: 'root',
})
export class DataService {
    private readonly context = {
        headers: {},
    };
    private networkError$ = new Subject<void>();

    constructor(
        private apollo: Apollo,
        private notificationService: NotificationService,
        private modalService: ModalService,
        private stateService: StateService,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        const debouncedNetworkError$ = this.networkError$.pipe(debounceTime(2000));
        this.networkError$.pipe(bufferWhen(() => debouncedNetworkError$)).subscribe(async () => {
            const { NetworkErrorComponent } = await import(
                '../../components/network-error-dialog/network-error.component'
            );
            this.modalService
                .fromComponent(NetworkErrorComponent, {
                    closable: true,
                })
                .subscribe();
        });
    }

    query<T = any, V = any>(
        query: DocumentNode | TypedDocumentNode<T, V> | TypedDocumentString<T, V>,
        variables?: V,
        options?: { fetchPolicy?: WatchQueryFetchPolicy; ssr?: boolean },
    ): Observable<T> {
        const context = { ...this.context };
        if (isPlatformServer(this.platformId) && options?.ssr !== true) {
            return EMPTY;
        }
        const queryDocumentNode = query instanceof String ? parse(query.toString()) : query;
        return this.apollo
            .watchQuery<T, V>({
                query: queryDocumentNode,
                variables,
                context,
                fetchPolicy: options?.fetchPolicy || 'cache-and-network',
            })
            .valueChanges.pipe(
                filter((result) => result.networkStatus === NetworkStatus.ready),
                map((response) => response.data),
                catchError((err, source) => {
                    console.log(err);
                    if (err.message === 'No Customer found for user') {
                        // User is authenticated (me != null) yet has no activeCustomer.
                        // This means they are logged in as an admin, so we will now
                        // log them out.
                        return this.mutate(SignOutDocument).pipe(
                            tap(() => this.stateService.setState('signedIn', false)),
                            switchMap(() =>
                                this.notificationService.info(
                                    'You were signed in as an Administrator, so have been signed out',
                                ),
                            ),
                            map(() => {
                                throw err;
                            }),
                        );
                    }
                    if ((err as any).networkError?.status === 0) {
                        this.networkError$.next();
                        captureException(err, (scope) => {
                            scope.setTag('networkError', 'true');
                            scope.setTag('operation', (queryDocumentNode.definitions[0] as any)?.name?.value);
                            return scope;
                        });
                        return EMPTY;
                    }

                    throw err;
                }),
            );
    }

    mutate<T = any, V = any>(
        mutation: DocumentNode | TypedDocumentNode<T, V> | TypedDocumentString<T, V>,
        variables?: V,
    ): Observable<T> {
        return this.apollo
            .mutate<T, V>({
                mutation: mutation instanceof String ? parse(mutation.toString()) : mutation,
                variables,
                context: this.context,
            })
            .pipe(map((response) => (response as any).data as T));
    }
}

<div class="rounded shadow-lg bg-white md:w-96" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="flex px-4 py-2 toast-header rounded-t items-baseline" [ngClass]="options.type">
        <strong class="flex-1">{{ options.title }}</strong>
        <button type="button" class="text-lg font-bold text-gray-700" data-dismiss="toast" aria-label="Close" (click)="close.next()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="p-4">
        <ng-container *ngIf="options.message">
            {{ options.message }}
        </ng-container>
        <ng-container *ngIf="options.templateRef">
            <ng-container *ngTemplateOutlet="options.templateRef; context: options.templateContext"></ng-container>
        </ng-container>
    </div>
</div>

var e = {
  NAME: "Name",
  DOCUMENT: "Document",
  OPERATION_DEFINITION: "OperationDefinition",
  VARIABLE_DEFINITION: "VariableDefinition",
  SELECTION_SET: "SelectionSet",
  FIELD: "Field",
  ARGUMENT: "Argument",
  FRAGMENT_SPREAD: "FragmentSpread",
  INLINE_FRAGMENT: "InlineFragment",
  FRAGMENT_DEFINITION: "FragmentDefinition",
  VARIABLE: "Variable",
  INT: "IntValue",
  FLOAT: "FloatValue",
  STRING: "StringValue",
  BOOLEAN: "BooleanValue",
  NULL: "NullValue",
  ENUM: "EnumValue",
  LIST: "ListValue",
  OBJECT: "ObjectValue",
  OBJECT_FIELD: "ObjectField",
  DIRECTIVE: "Directive",
  NAMED_TYPE: "NamedType",
  LIST_TYPE: "ListType",
  NON_NULL_TYPE: "NonNullType"
};
var r = {
  QUERY: "query",
  MUTATION: "mutation",
  SUBSCRIPTION: "subscription"
};
class GraphQLError extends Error {
  constructor(e, r, i, n, a, t, l) {
    super(e);
    this.name = "GraphQLError";
    this.message = e;
    if (a) {
      this.path = a;
    }
    if (r) {
      this.nodes = Array.isArray(r) ? r : [r];
    }
    if (i) {
      this.source = i;
    }
    if (n) {
      this.positions = n;
    }
    if (t) {
      this.originalError = t;
    }
    var o = l;
    if (!o && t) {
      var u = t.extensions;
      if (u && "object" == typeof u) {
        o = u;
      }
    }
    this.extensions = o || {};
  }
  toJSON() {
    return {
      ...this,
      message: this.message
    };
  }
  toString() {
    return this.message;
  }
  get [Symbol.toStringTag]() {
    return "GraphQLError";
  }
}
var i;
var n;
function error(e) {
  return new GraphQLError(`Syntax Error: Unexpected token at ${n} in ${e}`);
}
function advance(e) {
  e.lastIndex = n;
  if (e.test(i)) {
    return i.slice(n, n = e.lastIndex);
  }
}
var a = / +(?=[^\s])/y;
function blockString(e) {
  var r = e.split("\n");
  var i = "";
  var n = 0;
  var t = 0;
  var l = r.length - 1;
  for (var o = 0; o < r.length; o++) {
    a.lastIndex = 0;
    if (a.test(r[o])) {
      if (o && (!n || a.lastIndex < n)) {
        n = a.lastIndex;
      }
      t = t || o;
      l = o;
    }
  }
  for (var u = t; u <= l; u++) {
    if (u !== t) {
      i += "\n";
    }
    i += r[u].slice(n).replace(/\\"""/g, '"""');
  }
  return i;
}
function ignored() {
  for (var e = 0 | i.charCodeAt(n++); 9 === e || 10 === e || 13 === e || 32 === e || 35 === e || 44 === e || 65279 === e; e = 0 | i.charCodeAt(n++)) {
    if (35 === e) {
      while (10 !== (e = i.charCodeAt(n++)) && 13 !== e) {}
    }
  }
  n--;
}
var t = /[_A-Za-z]\w*/y;
var l = new RegExp("(?:(null|true|false)|\\$(" + t.source + ')|(-?\\d+)((?:\\.\\d+)?[eE][+-]?\\d+|\\.\\d+)?|("""(?:"""|(?:[\\s\\S]*?[^\\\\])"""))|("(?:"|[^\\r\\n]*?[^\\\\]"))|(' + t.source + "))", "y");
var o = function (e) {
  e[e.Const = 1] = "Const";
  e[e.Var = 2] = "Var";
  e[e.Int = 3] = "Int";
  e[e.Float = 4] = "Float";
  e[e.BlockString = 5] = "BlockString";
  e[e.String = 6] = "String";
  e[e.Enum = 7] = "Enum";
  return e;
}(o || {});
var u = /\\/g;
function value(e) {
  var r;
  var a;
  l.lastIndex = n;
  if (91 === i.charCodeAt(n)) {
    n++;
    ignored();
    var d = [];
    while (93 !== i.charCodeAt(n)) {
      d.push(value(e));
    }
    n++;
    ignored();
    return {
      kind: "ListValue",
      values: d
    };
  } else if (123 === i.charCodeAt(n)) {
    n++;
    ignored();
    var v = [];
    while (125 !== i.charCodeAt(n)) {
      if (null == (r = advance(t))) {
        throw error("ObjectField");
      }
      ignored();
      if (58 !== i.charCodeAt(n++)) {
        throw error("ObjectField");
      }
      ignored();
      v.push({
        kind: "ObjectField",
        name: {
          kind: "Name",
          value: r
        },
        value: value(e)
      });
    }
    n++;
    ignored();
    return {
      kind: "ObjectValue",
      fields: v
    };
  } else if (null != (a = l.exec(i))) {
    n = l.lastIndex;
    ignored();
    if (null != (r = a[o.Const])) {
      return "null" === r ? {
        kind: "NullValue"
      } : {
        kind: "BooleanValue",
        value: "true" === r
      };
    } else if (null != (r = a[o.Var])) {
      if (e) {
        throw error("Variable");
      } else {
        return {
          kind: "Variable",
          name: {
            kind: "Name",
            value: r
          }
        };
      }
    } else if (null != (r = a[o.Int])) {
      var s;
      if (null != (s = a[o.Float])) {
        return {
          kind: "FloatValue",
          value: r + s
        };
      } else {
        return {
          kind: "IntValue",
          value: r
        };
      }
    } else if (null != (r = a[o.BlockString])) {
      return {
        kind: "StringValue",
        value: blockString(r.slice(3, -3)),
        block: !0
      };
    } else if (null != (r = a[o.String])) {
      return {
        kind: "StringValue",
        value: u.test(r) ? JSON.parse(r) : r.slice(1, -1),
        block: !1
      };
    } else if (null != (r = a[o.Enum])) {
      return {
        kind: "EnumValue",
        value: r
      };
    }
  }
  throw error("Value");
}
function arguments_(e) {
  if (40 === i.charCodeAt(n)) {
    var r = [];
    n++;
    ignored();
    var a;
    do {
      if (null == (a = advance(t))) {
        throw error("Argument");
      }
      ignored();
      if (58 !== i.charCodeAt(n++)) {
        throw error("Argument");
      }
      ignored();
      r.push({
        kind: "Argument",
        name: {
          kind: "Name",
          value: a
        },
        value: value(e)
      });
    } while (41 !== i.charCodeAt(n));
    n++;
    ignored();
    return r;
  }
}
function directives(e) {
  if (64 === i.charCodeAt(n)) {
    var r = [];
    var a;
    do {
      n++;
      if (null == (a = advance(t))) {
        throw error("Directive");
      }
      ignored();
      r.push({
        kind: "Directive",
        name: {
          kind: "Name",
          value: a
        },
        arguments: arguments_(e)
      });
    } while (64 === i.charCodeAt(n));
    return r;
  }
}
function type() {
  var e;
  var r = 0;
  while (91 === i.charCodeAt(n)) {
    r++;
    n++;
    ignored();
  }
  if (null == (e = advance(t))) {
    throw error("NamedType");
  }
  ignored();
  var a = {
    kind: "NamedType",
    name: {
      kind: "Name",
      value: e
    }
  };
  do {
    if (33 === i.charCodeAt(n)) {
      n++;
      ignored();
      a = {
        kind: "NonNullType",
        type: a
      };
    }
    if (r) {
      if (93 !== i.charCodeAt(n++)) {
        throw error("NamedType");
      }
      ignored();
      a = {
        kind: "ListType",
        type: a
      };
    }
  } while (r--);
  return a;
}
var d = new RegExp("(?:(\\.{3})|(" + t.source + "))", "y");
var v = function (e) {
  e[e.Spread = 1] = "Spread";
  e[e.Name = 2] = "Name";
  return e;
}(v || {});
function selectionSet() {
  var e = [];
  var r;
  var a;
  do {
    d.lastIndex = n;
    if (null != (a = d.exec(i))) {
      n = d.lastIndex;
      if (null != a[v.Spread]) {
        ignored();
        var l = advance(t);
        if (null != l && "on" !== l) {
          ignored();
          e.push({
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: l
            },
            directives: directives(!1)
          });
        } else {
          ignored();
          if ("on" === l) {
            if (null == (l = advance(t))) {
              throw error("NamedType");
            }
            ignored();
          }
          var o = directives(!1);
          if (123 !== i.charCodeAt(n++)) {
            throw error("InlineFragment");
          }
          ignored();
          e.push({
            kind: "InlineFragment",
            typeCondition: l ? {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: l
              }
            } : void 0,
            directives: o,
            selectionSet: selectionSet()
          });
        }
      } else if (null != (r = a[v.Name])) {
        var u = void 0;
        ignored();
        if (58 === i.charCodeAt(n)) {
          n++;
          ignored();
          u = r;
          if (null == (r = advance(t))) {
            throw error("Field");
          }
          ignored();
        }
        var s = arguments_(!1);
        ignored();
        var c = directives(!1);
        var f = void 0;
        if (123 === i.charCodeAt(n)) {
          n++;
          ignored();
          f = selectionSet();
        }
        e.push({
          kind: "Field",
          alias: u ? {
            kind: "Name",
            value: u
          } : void 0,
          name: {
            kind: "Name",
            value: r
          },
          arguments: s,
          directives: c,
          selectionSet: f
        });
      }
    } else {
      throw error("SelectionSet");
    }
  } while (125 !== i.charCodeAt(n));
  n++;
  ignored();
  return {
    kind: "SelectionSet",
    selections: e
  };
}
function fragmentDefinition() {
  var e;
  var r;
  if (null == (e = advance(t))) {
    throw error("FragmentDefinition");
  }
  ignored();
  if ("on" !== advance(t)) {
    throw error("FragmentDefinition");
  }
  ignored();
  if (null == (r = advance(t))) {
    throw error("FragmentDefinition");
  }
  ignored();
  var a = directives(!1);
  if (123 !== i.charCodeAt(n++)) {
    throw error("FragmentDefinition");
  }
  ignored();
  return {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: e
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: r
      }
    },
    directives: a,
    selectionSet: selectionSet()
  };
}
var s = /(?:query|mutation|subscription|fragment)/y;
function operationDefinition(e) {
  var r;
  var a;
  var l;
  if (e) {
    ignored();
    r = advance(t);
    a = function variableDefinitions() {
      ignored();
      if (40 === i.charCodeAt(n)) {
        var e = [];
        n++;
        ignored();
        var r;
        do {
          if (36 !== i.charCodeAt(n++)) {
            throw error("Variable");
          }
          if (null == (r = advance(t))) {
            throw error("Variable");
          }
          ignored();
          if (58 !== i.charCodeAt(n++)) {
            throw error("VariableDefinition");
          }
          ignored();
          var a = type();
          var l = void 0;
          if (61 === i.charCodeAt(n)) {
            n++;
            ignored();
            l = value(!0);
          }
          ignored();
          e.push({
            kind: "VariableDefinition",
            variable: {
              kind: "Variable",
              name: {
                kind: "Name",
                value: r
              }
            },
            type: a,
            defaultValue: l,
            directives: directives(!0)
          });
        } while (41 !== i.charCodeAt(n));
        n++;
        ignored();
        return e;
      }
    }();
    l = directives(!1);
  }
  if (123 === i.charCodeAt(n)) {
    n++;
    ignored();
    return {
      kind: "OperationDefinition",
      operation: e || "query",
      name: r ? {
        kind: "Name",
        value: r
      } : void 0,
      variableDefinitions: a,
      directives: l,
      selectionSet: selectionSet()
    };
  }
}
function parse(e, r) {
  i = "string" == typeof e.body ? e.body : e;
  n = 0;
  return function document() {
    var e;
    var r;
    ignored();
    var a = [];
    do {
      if ("fragment" === (e = advance(s))) {
        ignored();
        a.push(fragmentDefinition());
      } else if (null != (r = operationDefinition(e))) {
        a.push(r);
      } else {
        throw error("Document");
      }
    } while (n < i.length);
    return {
      kind: "Document",
      definitions: a
    };
  }();
}
function parseValue(e, r) {
  i = "string" == typeof e.body ? e.body : e;
  n = 0;
  ignored();
  return value(!1);
}
function parseType(e, r) {
  i = "string" == typeof e.body ? e.body : e;
  n = 0;
  return type();
}
var c = {};
function visit(e, r) {
  var i = [];
  var n = [];
  try {
    var a = function traverse(e, a, t) {
      var l = !1;
      var o = r[e.kind] && r[e.kind].enter || r[e.kind] || r.enter;
      var u = o && o.call(r, e, a, t, n, i);
      if (!1 === u) {
        return e;
      } else if (null === u) {
        return null;
      } else if (u === c) {
        throw c;
      } else if (u && "string" == typeof u.kind) {
        l = u !== e;
        e = u;
      }
      if (t) {
        i.push(t);
      }
      var d;
      var v = {
        ...e
      };
      for (var s in e) {
        n.push(s);
        var f = e[s];
        if (Array.isArray(f)) {
          var m = [];
          for (var g = 0; g < f.length; g++) {
            if (null != f[g] && "string" == typeof f[g].kind) {
              i.push(e);
              n.push(g);
              d = traverse(f[g], g, f);
              n.pop();
              i.pop();
              if (null == d) {
                l = !0;
              } else {
                l = l || d !== f[g];
                m.push(d);
              }
            }
          }
          f = m;
        } else if (null != f && "string" == typeof f.kind) {
          if (void 0 !== (d = traverse(f, s, e))) {
            l = l || f !== d;
            f = d;
          }
        }
        n.pop();
        if (l) {
          v[s] = f;
        }
      }
      if (t) {
        i.pop();
      }
      var p = r[e.kind] && r[e.kind].leave || r.leave;
      var h = p && p.call(r, e, a, t, n, i);
      if (h === c) {
        throw c;
      } else if (void 0 !== h) {
        return h;
      } else if (void 0 !== u) {
        return l ? v : u;
      } else {
        return l ? v : e;
      }
    }(e);
    return void 0 !== a && !1 !== a ? a : e;
  } catch (r) {
    if (r !== c) {
      throw r;
    }
    return e;
  }
}
function mapJoin(e, r, i) {
  var n = "";
  for (var a = 0; a < e.length; a++) {
    if (a) {
      n += r;
    }
    n += i(e[a]);
  }
  return n;
}
function printString(e) {
  return JSON.stringify(e);
}
function printBlockString(e) {
  return '"""\n' + e.replace(/"""/g, '\\"""') + '\n"""';
}
var f = "\n";
var m = {
  OperationDefinition(e) {
    var r = e.operation;
    if (e.name) {
      r += " " + e.name.value;
    }
    if (e.variableDefinitions && e.variableDefinitions.length) {
      if (!e.name) {
        r += " ";
      }
      r += "(" + mapJoin(e.variableDefinitions, ", ", m.VariableDefinition) + ")";
    }
    if (e.directives && e.directives.length) {
      r += " " + mapJoin(e.directives, " ", m.Directive);
    }
    return "query" !== r ? r + " " + m.SelectionSet(e.selectionSet) : m.SelectionSet(e.selectionSet);
  },
  VariableDefinition(e) {
    var r = m.Variable(e.variable) + ": " + _print(e.type);
    if (e.defaultValue) {
      r += " = " + _print(e.defaultValue);
    }
    if (e.directives && e.directives.length) {
      r += " " + mapJoin(e.directives, " ", m.Directive);
    }
    return r;
  },
  Field(e) {
    var r = e.alias ? e.alias.value + ": " + e.name.value : e.name.value;
    if (e.arguments && e.arguments.length) {
      var i = mapJoin(e.arguments, ", ", m.Argument);
      if (r.length + i.length + 2 > 80) {
        r += "(" + (f += "  ") + mapJoin(e.arguments, f, m.Argument) + (f = f.slice(0, -2)) + ")";
      } else {
        r += "(" + i + ")";
      }
    }
    if (e.directives && e.directives.length) {
      r += " " + mapJoin(e.directives, " ", m.Directive);
    }
    if (e.selectionSet) {
      r += " " + m.SelectionSet(e.selectionSet);
    }
    return r;
  },
  StringValue(e) {
    if (e.block) {
      return printBlockString(e.value).replace(/\n/g, f);
    } else {
      return printString(e.value);
    }
  },
  BooleanValue: e => "" + e.value,
  NullValue: e => "null",
  IntValue: e => e.value,
  FloatValue: e => e.value,
  EnumValue: e => e.value,
  Name: e => e.value,
  Variable: e => "$" + e.name.value,
  ListValue: e => "[" + mapJoin(e.values, ", ", _print) + "]",
  ObjectValue: e => "{" + mapJoin(e.fields, ", ", m.ObjectField) + "}",
  ObjectField: e => e.name.value + ": " + _print(e.value),
  Document(e) {
    if (!e.definitions || !e.definitions.length) {
      return "";
    }
    return mapJoin(e.definitions, "\n\n", _print);
  },
  SelectionSet: e => "{" + (f += "  ") + mapJoin(e.selections, f, _print) + (f = f.slice(0, -2)) + "}",
  Argument: e => e.name.value + ": " + _print(e.value),
  FragmentSpread(e) {
    var r = "..." + e.name.value;
    if (e.directives && e.directives.length) {
      r += " " + mapJoin(e.directives, " ", m.Directive);
    }
    return r;
  },
  InlineFragment(e) {
    var r = "...";
    if (e.typeCondition) {
      r += " on " + e.typeCondition.name.value;
    }
    if (e.directives && e.directives.length) {
      r += " " + mapJoin(e.directives, " ", m.Directive);
    }
    return r += " " + m.SelectionSet(e.selectionSet);
  },
  FragmentDefinition(e) {
    var r = "fragment " + e.name.value;
    r += " on " + e.typeCondition.name.value;
    if (e.directives && e.directives.length) {
      r += " " + mapJoin(e.directives, " ", m.Directive);
    }
    return r + " " + m.SelectionSet(e.selectionSet);
  },
  Directive(e) {
    var r = "@" + e.name.value;
    if (e.arguments && e.arguments.length) {
      r += "(" + mapJoin(e.arguments, ", ", m.Argument) + ")";
    }
    return r;
  },
  NamedType: e => e.name.value,
  ListType: e => "[" + _print(e.type) + "]",
  NonNullType: e => _print(e.type) + "!"
};
var _print = e => m[e.kind](e);
function print(e) {
  f = "\n";
  return m[e.kind] ? m[e.kind](e) : "";
}
function valueFromASTUntyped(e, r) {
  switch (e.kind) {
    case "NullValue":
      return null;
    case "IntValue":
      return parseInt(e.value, 10);
    case "FloatValue":
      return parseFloat(e.value);
    case "StringValue":
    case "EnumValue":
    case "BooleanValue":
      return e.value;
    case "ListValue":
      var i = [];
      for (var n of e.values) {
        i.push(valueFromASTUntyped(n, r));
      }
      return i;
    case "ObjectValue":
      var a = Object.create(null);
      for (var t of e.fields) {
        a[t.name.value] = valueFromASTUntyped(t.value, r);
      }
      return a;
    case "Variable":
      return r && r[e.name.value];
  }
}
function valueFromTypeNode(e, r, i) {
  if ("Variable" === e.kind) {
    return i ? valueFromTypeNode(i[e.name.value], r, i) : void 0;
  } else if ("NonNullType" === r.kind) {
    return "NullValue" !== e.kind ? valueFromTypeNode(e, r, i) : void 0;
  } else if ("NullValue" === e.kind) {
    return null;
  } else if ("ListType" === r.kind) {
    if ("ListValue" === e.kind) {
      var n = [];
      for (var a of e.values) {
        var t = valueFromTypeNode(a, r.type, i);
        if (void 0 === t) {
          return;
        } else {
          n.push(t);
        }
      }
      return n;
    }
  } else if ("NamedType" === r.kind) {
    switch (r.name.value) {
      case "Int":
      case "Float":
      case "String":
      case "Bool":
        return r.name.value + "Value" === e.kind ? valueFromASTUntyped(e, i) : void 0;
      default:
        return valueFromASTUntyped(e, i);
    }
  }
}
export { c as BREAK, GraphQLError, e as Kind, r as OperationTypeNode, parse, parseType, parseValue, print, printBlockString, printString, valueFromASTUntyped, valueFromTypeNode, visit };

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

import { DEFAULT_CURRENCY_CODE } from "../../common/constants";
import { StorageService } from "./storage/storage.service";

export interface AppState {
    signedIn: boolean;
    activeOrderId: string | null;
    defaultWishlistId: string | null;
    lastCollectionSlug: string | null;
    cartDrawerOpen: boolean;
    mobileNavMenuIsOpen: boolean;
    displayCurrency: string;
}

export const initialState: AppState = {
    signedIn: false,
    activeOrderId: null,
    defaultWishlistId: null,
    lastCollectionSlug: null,
    cartDrawerOpen: false,
    mobileNavMenuIsOpen: false,
    displayCurrency: DEFAULT_CURRENCY_CODE,
};

/**
 * A simple, observable store of global app state.
 */
@Injectable({
    providedIn: 'root',
})
export class StateService {
    private state: AppState = initialState;
    private readonly stateSubject = new BehaviorSubject<AppState>(initialState);

    constructor(private storageService: StorageService) {
        if (typeof window !== 'undefined') {
            Object.defineProperty(window, 'appState', {
                get: () => this.stateSubject.value,
            });
        }
        const signedIn = this.storageService.getFromLocalStorage('signedIn');
        if (signedIn !== null) {
            this.setState('signedIn', signedIn);
        }
    }

    setState<T extends keyof AppState>(key: T, value: AppState[T]) {
        this.state[key] = value;
        this.stateSubject.next(this.state);
        if (key === 'signedIn') {
            this.storageService.setInLocalStorage('signedIn', value as boolean);
        }
    }

    select<R>(selector: (state: AppState) => R): Observable<R> {
        return this.stateSubject.pipe(map(selector), distinctUntilChanged());
    }
}
